<style>
.bg-readonly input, .bg-readonly textarea {
    background-color: rgb(235, 235, 235);
}
.vdp-datepicker__calendar {
    bottom: 100%;
    left: -20%;
}
.input-price input {
    text-align: right;
}
</style>
<template>
    <vx-card :title="'Detail Log Import'">
        <div class="vx-row mb-6" style="width: 60%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>

        <hr>
        <vs-row>
            <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                <p style="font-weight: bold;">Data</p>
            </vs-col>
            <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                <p style="font-weight: bold;">Status</p>
            </vs-col>
            <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                <p style="font-weight: bold;">Remark</p>
            </vs-col>
        </vs-row>
        <hr>

        <div v-for="(line, index) in lines" :key="index">
            <vs-row>
                <vs-col vs-w="4">
                    <!-- <div v-for="key in orderedKeys" :key="key">
                        <div v-if="line.data.hasOwnProperty(key)">
                            <p><strong>{{ key }}:</strong> {{ line.data[key] }}</p>
                        </div>
                    </div> -->
                    <div v-for="(value, key) in parseJson(line.data)" :key="key">
                        <p><strong>{{ key }}:</strong> {{ value }}</p>
                    </div>
                </vs-col>
                <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                    <p>{{ line.status }}</p>
                </vs-col>
                <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                    <p>{{ getRow(line.data) }} {{ line.remark }}</p>
                </vs-col>
            </vs-row>
            <hr>
        </div>
    </vx-card>
</template>

<script>

import _ from "lodash";
import Date from "@/components/Date.vue";
import moment from "moment";

export default {
    components: {
        
    },
    data() {
        return {
            id: null,            
            lines: [],            
        }
    },
    mounted() {
        this.id = this.$route.params.id
        if (this.id) {
            this.getData()
        }
    },
    methods: {        
        getData() {
            this.$vs.loading();            
            this.$http
                .get(this.$store.state.master.buyingPrice.baseUrlPath + "-" + "import-log/" + this.id)
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {            
                        this.lines = resp.data.data_line                                   
                    }
                });
        },
        parseJson(jsonString) {
            const orderedKeys = ['sku_code', 'name', 'unit_name', 'from', 'to', 'price', 'note', 'currency', 'valid_from', 'valid_to', 'calculation', 'supplier_code', 'supplier_name', 'warehouse_code', 'warehouse_name'];
            const parsedData = JSON.parse(jsonString);
            const orderedData = {};
            orderedKeys.forEach(key => {
            if (Object.prototype.propertyIsEnumerable.call(parsedData, key)) {
                    orderedData[key] = parsedData[key];
                }
            });
            return orderedData;
        },
        getRow(jsonString) {
            // const orderedKeys = ['sku_code', 'name', 'unit_name', 'from', 'to', 'price', 'note', 'currency', 'valid_from', 'valid_to', 'calculation', 'supplier_code', 'supplier_name', 'warehouse_code', 'warehouse_name'];
            const parsedData = JSON.parse(jsonString);
            // const orderedData = {};
            // orderedKeys.forEach(key => {
            // if (Object.prototype.propertyIsEnumerable.call(parsedData, key)) {
            //         orderedData[key] = parsedData[key];
            //     }
            // });
            return parsedData["row"];
        },
        handleBack() {
            this.$router.push({
                name: this.$store.state.master.buyingPrice.baseRouterName,
            });
        },
    },
    computed:{
        formatDate: () => {
            return (val) => {
                if (!val || !moment.utc(val).isValid()) {
                    return ''; // Return empty string for invalid date or empty input
                }
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formattedNumber() {
            const formattedValue = parseFloat(this.rawInput).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });

            return formattedValue;
        },
    }    
}
</script>